import {plural} from 'typed-locale';
import {Dictionary} from './en';

export const fr: Dictionary = {
  locale: 'fr',
  email: 'Email',
  email_example: 'jean.dupont@gmail.com',
  password: 'Mot de passe',
  login: 'Connexion',
  try: 'Essayer',
  ok: 'Ok',
  blog: 'Blog',
  start: 'Commencer',
  ask_for_demo: 'Demander une démo',
  comming_soon: 'Bientôt disponible',
  logout: 'Déconnexion',
  signup: 'Inscription',
  articles: 'Articles',
  validate: 'Valider',
  head_title: 'Questovery - Créez vos jeux de piste numériques sur application mobile',
  location_is_required: 'La localisation est requise',
  activate_geolocation_for_this_step: 'Activez la géolocalisation pour cette étape',
  we_need_your_location: 'Nous avons besoin de votre localisation pour définir la position initiale de la carte.',
  head_description:
    'Créez vos jeux de piste numériques sur application mobile, chasses au trésor, escape games personnalisés. Idéal pour les team buildings, les anniversaires, les visites de ville, les lieux touristiques.',
  send_reset_password_email: 'Envoyer un email de réinitialisation',
  email_sent: 'Email envoyé',
  repeat_password: 'Répéter le mot de passe',
  reset_email_sent:
    'Un email de réinitialisation a été envoyé à votre adresse email, veuillez vérifier votre boîte de réception.',
  forgot_password: 'Mot de passe oublié',
  back_to_login: 'Retour à la connexion',
  scan_qr_code: 'Scanner le QR code',
  not_yet_registered: 'Pas encore inscrit ?',
  already_have_an_account: "J'ai déjà un compte",
  company: 'Société',
  features: 'Fonctionnalités',
  month: 'Mois',
  you_have_no_subscription:
    'Vous pouvez créer gratuitement vos quêtes mais vous ne pourrez pas les partager. Quand vous serez prêt, vous pourrez souscrire à notre abonnement pour inviter vos premières équipes.',
  prices_in_euros_vat_excluded: 'Prix en euros, hors taxes',
  read_terms_of_use: 'Lire les conditions générales de vente',
  activate_your_subscription: 'Activez votre abonnement',
  teams_count_during_the_month: "Nombre d'équipes durant le mois",
  activate_your_subscription_description:
    "Achetez des crédits d'équipe ou souscrivez à notre abonnement pour utiliser vos quêtes.",
  read_end_user_license_agreement: 'Lire le contrat de licence utilisateur final',
  read_privacy_policy: 'Lire la politique de confidentialité',
  accept_terms_of_use: "J'accepte les conditions générales de vente",
  site: 'Site',
  about: 'À propos',
  contact_us: 'Contactez-nous',
  legal: 'Mentions légales',
  terms_of_use: 'Conditions générales de vente',
  docs: 'Docs',
  end_user_license_agreement: 'Contrat de licence utilisateur final',
  privacy_policy: 'Politique de confidentialité',
  home: 'Accueil',
  cta_title: 'Créez votre propre chasse au trésor avec la meilleure expérience utilisateur',
  cta_subtitle:
    'Questovery est une plateforme qui vous permet de créer votre propre jeu de piste en quelques clics. Faites du team building, une chasse au trésor, un escape game, une visite de ville, un anniversaire, et plus encore.',
  cta_feat_1_title: 'Combinez le meilleur du réel et du virtuel',
  cta_feat_1_subtitle:
    'Notre plateforme vous permet de créer des quêtes avec un système de géolocalisation, et des QR codes.',
  cta_feat_2_title: "Une quête qui s'adapte à vos besoins",
  cta_feat_2_subtitle:
    'Personnalisez votre quête avec vos propres questions, vos propres images, vos propres vidéos, et plus encore.',
  cta_feat_3_title: "L'expérience utilisateur au coeur de nos préoccupations",
  cta_feat_3_subtitle:
    'Notre plateforme est conçue pour être la plus intuitive possible. Notre application mobile est en constante évolution pour fournir la meilleure expérience utilisateur possible.',
  cta_screen_1_title: 'Interface intuitive',
  cta_screen_1_subtitle:
    'Avec notre application web, vous pouvez créer votre quête en quelques minutes. Carte interactive, glisser-déposer, tout est conçu pour vous faciliter la vie.',
  cta_price_title: 'Tarification simple',
  subscription_plan_description: 'Commencez à 4,5€ puis 0,5€ par équipe par mois après 10 équipes',
  cta_price_subtitle:
    'Pas de frais cachés, pas de carte de crédit requise. Pas d engagement. Commencez gratuitement et passez à la version payante quand vous en avez besoin.',
  plan_questovery: 'Abonnement Questovery',
  team: 'Équipe',
  prepaid_team_price_description: 'Achetez des équipes prépayées pour inviter des équipes à jouer à vos quêtes.',
  plan_questovery_description:
    'Vous pouvez acheter des équipes prépayées pour inviter des équipes à jouer à vos quêtes ou vous pouvez également souscrire à un abonnement pour un accès illimité à la plateforme et être facturé uniquement pour les équipes qui jouent à vos quêtes pendant le mois.',
  plan_free: 'Gratuit',
  plan_free_description: 'Pour les utilisateurs occasionnels qui veulent essayer la plateforme',
  plan_pro: 'Pro',
  plan_pro_description:
    'Pour les professionnels comme les agences événementielles, les entreprises de team building qui veulent créer des quêtes pour leurs clients',
  plan_max: 'Max',
  welcome_email_content:
    'Vous recevez cet email car vous avez créé un compte sur Questovery. Veuillez trouver ci-dessous votre code de vérification pour activer votre compte.',
  plan_max_description: 'Pour les lieux touristiques qui veulent créer des quêtes pour leurs visiteurs',
  plan_unlimited: 'Illimité',
  plan_unlimited_description: "Si vous n'aimez pas les limites",
  unlimited_teams: 'Équipes illimitées',
  a_quest_with_unlimited_teams: 'Une quête avec des équipes illimitées',
  add_images_and_audio: 'Ajouter des images et des audios',
  all_features_of_free_plan: 'Toutes les fonctionnalités du plan gratuit',
  full_edition_software: "Logiciel d'édition complet",
  geolocate_steps: 'Géolocaliser les étapes',
  manage_steps_and_questions: 'Gérer les étapes et les questions',
  support_with_chat_and_email: 'Support avec chat et email',
  unlimited_quests: 'Quêtes illimitées',
  invite_teams_up_to_25_by_quest: 'Invitez des équipes jusqu à 25 par quête',
  mobile_access: 'Accès mobile',
  lunch_mobile_app_and_scan_qr_code_to_manage_quest:
    'Lancez l application mobile et scannez le QR code pour gérer la quête',
  create: 'Créer',
  name: 'Nom',
  new_quest: 'Nouvelle quête',
  my_quests: 'Mes quêtes',
  new_quest_description: 'Créez une nouvelle quête. Vous pourrez la modifier et la tester avant de la partager.',
  update: 'Mettre à jour',
  profile: 'Profil',
  profile_description: 'Gérez votre profil et vos informations de facturation.',
  cancel: 'Annuler',
  delete: 'Supprimer',
  confirm: 'Confirmer',
  country: 'Pays',
  delete_account: 'Supprimer le compte',
  delete_account_confirm: 'Êtes-vous sûr de vouloir supprimer votre compte ?',
  delete_account_description: 'Supprimez votre compte',
  manage_subscriptions: 'Gérer les abonnements',
  verify_your_email: 'Vérifiez votre email',
  verify_your_email_description: 'Vérifiez votre email pour activer votre compte.',
  send_verification_email: 'Envoyer un email de vérification',
  verify: 'Vérifier',
  manage_invoices: 'Gérer les factures',
  months: 'Mois',
  subscriptions: 'Abonnements',
  subcribe: 'Souscrire',
  save: 'Enregistrer',
  your_subscription: 'Votre abonnement',
  settings: 'Paramètres',
  your_subscription_description: 'Obtenez des factures, gérez votre abonnement avec stripe',
  back_to_quest: 'Retour à la quête',
  delete_step: 'Supprimer l étape',
  delete_step_confirm: 'Êtes-vous sûr de vouloir supprimer cette étape ?',
  add_option: 'Ajouter une option',
  audio: 'Audio',
  description: 'Description',
  audio_comming_soon: "L'audio arrive bientôt, vous pourrez ajouter de l'audio à vos étapes.",
  enigma_type: 'Type d énigme',
  no_enigma: 'Pas d énigme',
  message_without_enigma: 'Message sans énigme',
  code: 'Code',
  bonus: 'Bonus',
  malus: 'Malus',
  options: 'Options',
  mcq: 'QCM',
  arrival: 'Arrivée',
  delete_quest: 'Supprimer la quête',
  delete_quest_confirm: 'Êtes-vous sûr de vouloir supprimer cette quête ?',
  delete_team: 'Supprimer l équipe',
  delete_team_confirm: 'Êtes-vous sûr de vouloir supprimer cette équipe ?',
  here_is_the_results_of_your_quest: 'Voici les résultats de votre quête.',
  invite_teams_with_qr_code: 'Invitez des équipes avec un QR code',
  steps: 'Étapes',
  invite_team_qr_code_description:
    'Invitez des équipes en leur envoyant le QR code de la quête. Ils pourront scanner le QR code avec l application mobile Questovery pour rejoindre la quête.',
  invite_team_qr_code_title: 'Invitez des équipes avec un QR code',
  launch_the_team_quest: 'Lancez la quête de l équipe',
  launch_the_team_quest_description:
    'Lancez la quête de l équipe en cliquant sur le bouton ci-dessous. Vous pourrez voir l avancement de l équipe en temps réel.',
  startings: 'Départs',
  steps_description:
    'Créez des étapes pour votre quête, chaque étape est un endroit où les équipes devront se rendre. Triez-les par glisser-déposer.',
  forgot_email_content:
    'Vous recevez cet email car vous avez demandé à réinitialiser votre mot de passe.Veuillez trouver le code de réinitialisation ci-dessous, ou bien cliquez sur le lien ci-dessous pour réinitialiser votre mot de passe.',
  signup_email_content: 'Vous recevez cet email car vous avez créé un compte sur Questovery.',
  question_message: 'Question / message',
  signup_email_title: 'Bienvenue sur Questovery',
  image_deleted: 'Image supprimée',
  look_in_the_map: 'Regardez sur la carte',
  test: 'Tester',
  test_the_quest: 'Tester la quête',
  test_the_quest_description: 'Démarrez une fausse équipe et testez la quête pour voir si tout va bien.',
  updates_saved: 'Modifications enregistrées',
  select: 'Sélectionner',
  image: 'Image',
  record: 'Enregistrer',
  play: 'Jouer',
  cannot_retrieve_user: 'Impossible de récupérer l utilisateur',
  permission_denied: 'Permission refusée',
  location: 'Localisation',
  enigma: 'Énigme',
  error: 'Erreur',
  new_step: 'Nouvelle étape',
  new_step_description: "Créez une nouvelle étape. C'est ici que les équipes devront se rendre durant la quête.",
  answer: 'Réponse',
  add: 'Ajouter',
  malus_by_wrong_answer: 'Malus par mauvaise réponse',
  allow_teams_to_start_by_themselves: 'Autoriser les équipes à démarrer par elles-mêmes',
  good_answer: 'Bonne réponse',
  good_answer_description: 'Bonne réponse, bravo !',
  wrong_answer: 'Mauvaise réponse',
  wrong_answer_description: 'Mauvaise réponse, veuillez réessayer.',
  lock: 'Verrouiller',
  unlock: 'Déverrouiller',
  join: 'Rejoindre',
  player_name: 'Nom de joueur',
  your_team: 'Votre équipe',
  contact_us_description:
    "Si vous avez des questions, des suggestions ou des bugs à signaler, n'hésitez pas à nous contacter par email ou par chat sur notre site web.",
  team_members: 'Membres de l équipe',
  team_members_description:
    'Voici les membres de votre équipe, vous pouvez en inviter d autres ne leur partageant le QR code de l équipe.',
  manage: 'Gérer',
  join_quest: 'Rejoindre la quête',
  join_quest_description: 'Rejoignez la quête en scannant le QR code de la quête.',
  quest_in_progress: 'Quête en cours',
  quest_in_progress_description: 'La quête est en cours, dépêchez-vous de la terminer !',
  scan_the_qr_code_to_login: 'Scannez le QR code pour vous connecter',
  waiting_for_the_quest_to_start: 'En attente du démarrage de la quête',
  go_back: 'Retour',
  purchase_prepaid_teams: "Acheter des crédits d'équipe",
  you_unlocked_the_next_step: 'Vous avez débloqué la prochaine étape',
  next_step: 'Étape suivante',
  send_answer: 'Envoyer la réponse',
  come_closer: 'Approchez-vous',
  come_closer_description: 'Vous êtes trop loin de l étape, approchez-vous pour débloquer la prochaine étape.',
  quest_ended: 'Quête terminée',
  quest_ended_description: 'La quête est terminée, merci d avoir joué.',
  see_results: 'Voir les résultats',
  admin: 'Admin',
  admin_actions_description:
    'Vous pouvez déverrouiller des étapes pour passer la géolocalisation et simuler de bonnes ou de mauvaises réponses.',
  select_the_good_aswer: 'Sélectionnez la bonne réponse',
  upgrade_your_plan: 'Mettez à jour votre abonnement',
  upgrade_your_plan_description:
    'Cette fonctionnalité est réservée aux abonnements payants. Vous pouvez mettre à jour votre abonnement pour débloquer cette fonctionnalité.',
  signup_subscription: 'Vous devez souscrire à notre abonnement pour accéder à notre plateforme.',
  signup_subscription_description:
    "Vous allez être redirigé vers la page d'abonnement pour souscrire à notre abonnement. Vous bénéficierez de 30 jours d'essai gratuit. Après cette période, vous serez facturé en fonction du nombre d'équipes qui ont joué à vos quêtes durant le mois. Chaque mois vous bénéficiez de 5 équipes gratuites.",
  welcome: 'Bienvenue',
  welcome_message:
    "Bienvenue sur Questovery! Vous allez recevoir un email de vérification pour activer votre compte et définir votre mot de passe. Si vous n'avez pas reçu l'email, veuillez vérifier votre dossier de spam.",
  define_password: 'Définir le mot de passe',
  monthly_subscription: 'Abonnement mensuel',
  one_time_payment: 'Paiement unique',
  teams: 'Équipes',
  welcome_unlimited_email_content:
    "Merci de participer à l'aventure Questovery. Votre compte est maintenant activé. Voici votre mot de passe :",
  onboarding: {
    welcome: {
      welcome_to_questovery: 'Bienvenue sur Questovery',
      welcome_to_questovery_description:
        'Créez vos jeux de piste numériques sur application mobile, chasses au trésor, escape games personnalisés. Idéal pour les team buildings, les anniversaires, les visites de ville, les lieux touristiques.',
      let_s_get_started: 'Commençons',
    },
    choices: {
      how_do_you_want_to_use_questovery: 'Comment souhaitez-vous utiliser Questovery ?',
      choices_description: "Choisissez l'option qui vous correspond le mieux.",
      individual: 'Pour moi et mes amis',
      event: 'Pour un événement',
      tourism: 'Pour un lieu touristique',
      game_choice: 'Je veux juste jouer',
      create_a_scavenger_hunt: 'Créez un jeu de piste',
    },
    tracking_consent: {
      consent: 'Consentement',
      consent_description:
        "Avant de continuer, nous devons vous demander votre consentement pour utiliser vos données ainsi que nos conditions d'utilisation. Soyez rassuré, ces données ne seront utilisées que pour améliorer notre service.",
      read_terms_of_use: "Lire les conditions d'utilisation",
      read_end_user_license_agreement: 'Lire le contrat de licence utilisateur final',
      read_privacy_policy: 'Lire la politique de confidentialité',
      accept: "J'accepte",
    },
    event: {
      you_want_to_create_a_quest_for_an_event: 'Vous voulez créer un jeu de piste pour un événement ?',
      you_want_to_create_a_quest_for_an_event_description:
        "Avec Questovery, planifiez un jeu de piste pour un événement, un anniversaire, un mariage, un team building. Ajoutez des étapes, des énigmes, des images, et des audios pour une expérience inoubliable. Une fois votre quête prête, partagez-la avec vos invités pour qu'ils puissent y participer avec un QR code.",
      for_quest_creation_use_our_web_app:
        'Pour créer votre quête, rendez-vous sur notre application web et inscrivez-vous pour commencer.',
      register_on_questovery: "Je m'inscris sur questovery.com",
      i_already_have_an_account: "J'ai déjà un compte",
    },
    individual: {
      you_want_to_create_a_quest: 'Vous voulez créer un jeu de piste et le partager avec vos amis ?',
      you_want_to_create_a_quest_description:
        "Avec Questovery, créez un jeu de piste pour vos amis, votre famille, ou vous-même. Ajoutez des étapes, des énigmes, des images, et des audios pour une expérience inoubliable. Une fois votre quête prête, partagez-la avec vos amis pour qu'ils puissent y participer avec un QR code.",
      for_quest_creation_use_our_web_app:
        'Pour créer votre quête, rendez-vous sur notre application web et inscrivez-vous pour commencer.',
      register_on_questovery: "Je m'inscris sur questovery.com",
      i_already_have_an_account: "J'ai déjà un compte",
    },
    tourism: {
      you_want_to_create_a_quest_for_a_touristic_place:
        'Vous voulez créer un jeu de piste pour un lieu touristique ou une ville ?',
      you_want_to_create_a_quest_for_a_touristic_place_description:
        'Avec Questovery, créez un jeu de piste pour un lieu touristique, une ville, un parc. Ajoutez des étapes, des énigmes, des images, et des audios pour une expérience inoubliable. Une fois votre quête prête, rendez son QR code disponible pour que les visiteurs puissent y participer.',
      for_quest_creation_use_our_web_app:
        'Pour créer votre quête, rendez-vous sur notre application web et inscrivez-vous pour commencer.',
      register_on_questovery: "Je m'inscris sur questovery.com",
      i_already_have_an_account: "J'ai déjà un compte",
    },
    game: {
      you_want_to_play_a_quest: 'Vous voulez juste participer à un jeu de piste ?',
      you_want_to_play_a_quest_description:
        "Avec Questovery, participez à un jeu de piste organisé par quelqu'un d'autre. Scannez le QR code de la quête pour commencer.",
      start_playing: 'Commencer à jouer',
    },
    location_consent: {
      location: 'Localisation',
      location_consent_description:
        'Pour utiliser Questovery, vous devez activer la localisation sur votre appareil. Nous ne stockons pas votre localisation, cela nous permet uniquement de savoir quand débloquer les étapes de la quête lorsque vous êtes à proximité.',
      accept: "J'accepte",
    },
    organize: {
      manage_my_quests: 'Gérez vos quêtes',
      you_re_already_a_quest_creator: 'Vous êtes déjà un créateur',
      get_qr_code_to_login_description:
        "Utilisez le QR code depuis la web app pour vous connecter à l'application mobile.",
    },
  },
  prepaid_teams: 'Équipes prépayées',
  prepaid_teams_description: plural({
    one: 'Vous avez une équipe prépayée. Vous pouvez lancer une quête pour cette équipe.',
    other: 'Vous avez {{count}} équipes prépayées. Vous pouvez utiliser ces crédits pour lancer des quêtes.',
    none: "Vous n'avez pas d'équipe prépayée. Pour lancez une quête, vous pouvez en acheter dans la section paramètres.",
  }),
};
