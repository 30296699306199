import z from 'zod';
import {Dictionary, en} from './en';
import {fr} from './fr';
export {createTranslator, createTranslatorFromDictionary} from 'typed-locale';

export type {Dictionary};

const dictionary = {en, fr};

export type DictionaryLanguage = keyof typeof dictionary;
export type Dictionaries = Record<DictionaryLanguage, Dictionary>;

export const dictionaryLanguages = Object.keys(dictionary) as [DictionaryLanguage];
export const dictionaryLanguageSchema = z.enum(dictionaryLanguages);

export const getDictionary = (lang?: DictionaryLanguage) => dictionary[lang || 'en'] as Dictionary;

export const getSafeDictionaryLanguage = (...languages: (string | undefined | null)[]): DictionaryLanguage => {
  for (const maybeLocale of languages) {
    const dictionary = dictionaryLanguageSchema.safeParse(maybeLocale);
    if (dictionary.success) {
      return dictionary.data;
    }
  }
  return 'en';
};

export const getDictionaryFromRequest = (headers: Headers, langInParams?: string): DictionaryLanguage => {
  return getSafeDictionaryLanguage(langInParams, headers.get('Accept-Language')?.split(',')[0].split('-')[0] || 'en');
};
